<template>
  <div style="position: relative;width: 100vw;height: 100vh;">

    <div class="launch-weapp" v-show="flag">
      <div class="launch-weapp-content">
        <div style="width: 100%;text-align: center">
          <p style="color: #333333;font-size: 18px;font-weight: 500">即将打开小程序</p>
        </div>
        <div style="display: flex;flex-direction: row;margin-top: 20px">
          <p class="launch-weapp-btn-cancel">取消</p>
          <div class="guideBtn">打开小程序</div>
        </div>
      </div>
    </div>
    <div style="position: absolute">
      <div type="primary" size="small" @click="flag=!flag">切换</div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      flag: true
    }
  }
}
</script>

<style scoped>

.guideBtn {
  padding: 6px 18px;
  text-align: center;
  box-sizing: border-box;
  background-color: #213A8B;
  border-radius: 18px;
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.launch-weapp {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.launch-weapp-content {
  border-radius: 8px;
  width: 80%;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
}

.launch-weapp-btn-cancel {
  text-align: center;
  line-height: 42px;
  width: 120px;
  height: 42px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #092E7F;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #092E7F;
}

</style>
